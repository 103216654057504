import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    cursor: 'pointer',
    // margin: '2px',
    overflow: 'hidden',
    textDecoration: 'none',
    color: 'black',

    [theme.breakpoints.up('sm')]: {
      '& >:nth-child(1):hover': {
        //filter: 'drop-shadow(0px 0px 1px #899BAF)',
        // border: '1px solid #899baf',
      },
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}));

const CommonCardContainer = props => {
  const { isOpenNewTab, children, url, target, willLink } = props;
  const classes = useStyles();
  const onCardClick = e => {
    e.stopPropagation();
    if (
      e.target.classList.value.indexOf('cardNotClicked') == -1 &&
      e.target.parentElement.classList.value.indexOf('cardNotClicked') == -1 &&
      url
    ) {
      if (isOpenNewTab && typeof window !== 'undefined') {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  };

  return willLink ? (
    <a
      className={classes.cardContainer}
      href={url}
      target={target ?? '_blank'}
      rel="noreferrer"
      style={{ color: 'inherit' }}>
      {children}
    </a>
  ) : (
    <div
      onClick={onCardClick}
      className={classes.cardContainer}
      target={target ?? '_blank'}
      rel="noreferrer"
      style={{ color: 'inherit' }}>
      {/* <a
        className={classes.cardContainer}
        href={url}
        target={target ?? '_blank'}
        rel="noreferrer"
        style={{ color: 'inherit' }}> */}
      {children}
      {/* </a> */}
    </div>
  );
};

export default CommonCardContainer;
