export const SessionStorage = ({
  investment_index,
  cityName,
  property_id,
  projectName,
  buildingName,
  bhk_types,
  furnishStatus,
  developer_name,
  livability_index,
  location,
  module_type,
  images,
  street_url,
  video_url,
  p_we_recommend_count,
  sqft,
  minPrice,
  maxPrice,
  minCarpetArea,
  furnishingStatus,
  maxCarpetArea,
  isResale = false,
}) => {
  let moduleType = isResale ? 'resale' : module_type;

  let newProjectData = [];
  if (JSON.parse(localStorage.getItem(moduleType))) {
    newProjectData = JSON.parse(localStorage.getItem(moduleType));
    const found = newProjectData.some(
      el => el.id.toString() === property_id.toString()
    );

    if (!found) {
      newProjectData.push({
        bestForInvestmentCount:
          // investment_index === null || investment_index === undefined
          investment_index > 0 ? '0' : investment_index.toString(),
        cityName: cityName.toString(),
        configurationCount: bhk_types.toString(),
        configurationTypeCount: bhk_types.toString(),
        bhkTypes: bhk_types.toString(),
        furnishStatus: furnishStatus,
        developerName: developer_name.toString(),
        id: property_id.toString(),
        liveabilityIndexCount:
          // livability_index === null || livability_index === undefined
          livability_index > 0 ? '0' : livability_index.toString(),
        localityName: location.toString(),
        moduleType: moduleType.toString(),
        price:
          minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice)
            ? maxPrice !== minPrice
              ? minPrice + ' - ' + maxPrice
              : minPrice
            : 'Price On Request',
        projectImage: images,
        image: images,
        projectName: projectName.toString(),
        buildingName: buildingName,
        propertyId: property_id.toString(),
        streetView: street_url.toString(),
        videoLink: video_url.toString(),
        weRecommendCount:
          // p_we_recommend_count === null || p_we_recommend_count === undefined
          p_we_recommend_count > 0 ? '0' : p_we_recommend_count.toString(),
        // sqft: sqft === null || sqft === undefined ? '0' : sqft.toString(),
        sqft: sqft > 0 ? '0' : sqft.toString(),
        maxCarpetArea: maxCarpetArea,
        minCarpetArea:
          // minCarpetArea === null || minCarpetArea === undefined
          minCarpetArea > 0 ? '0' : minCarpetArea?.toString(),
        furnishingStatus: (furnishingStatus ?? 0).toString(),
        isResale: isResale,
      });
      localStorage.setItem(moduleType, JSON.stringify(newProjectData));
      window.dispatchEvent(new Event('storage'));
    } else {
      const newArray = newProjectData.filter(
        item => item.id.toString() !== property_id.toString()
      );

      localStorage.setItem(moduleType, JSON.stringify(newArray));
      window.dispatchEvent(new Event('storage'));
    }

    // } else {
    //   newProjectData.push({
    //     bestForInvestmentCount:
    //       // investment_index === null || investment_index === undefined
    //       investment_index
    //         ? '0'
    //         : investment_index.toString(),
    //     cityName: cityName.toString(),
    //     configurationCount: bhk_types.toString(),
    //     configurationTypeCount: bhk_types.toString(),
    //     bhkTypes: bhk_types.toString(),
    //     furnishStatus: furnishStatus,
    //     developerName: developer_name.toString(),
    //     id: property_id.toString(),
    //     liveabilityIndexCount:
    //       livability_index === null || livability_index === undefined
    //         ? '0'
    //         : livability_index.toString(),
    //     localityName: location.toString(),
    //     moduleType: moduleType.toString(),
    //     price:
    //       minPrice && maxPrice && parseInt(minPrice) && parseInt(maxPrice)
    //         ? maxPrice !== minPrice
    //           ? minPrice + ' - ' + maxPrice
    //           : minPrice
    //         : 'Price On Request',
    //     projectImage: images,
    //     image: images,
    //     projectName: projectName.toString(),
    //     buildingName: buildingName,
    //     propertyId: property_id.toString(),
    //     streetView: street_url.toString(),
    //     videoLink: video_url.toString(),
    //     weRecommendCount: p_we_recommend_count?.toString(),
    //     sqft: sqft.toString(),
    //     maxCarpetArea: maxCarpetArea,
    //     minCarpetArea: sqft.toString(),
    //     furnishingStatus: (furnishingStatus ?? 0).toString(),
    //     isResale: isResale,
    //   });
    //   localStorage.setItem(moduleType, JSON.stringify(newProjectData));
    //   window.dispatchEvent(new Event('storage'));
    // }
  }
};
export default SessionStorage;
