import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    '.Mui-focused': {
      backgroundColor: '#000',
    },

    input: {
      '& :selected': {
        backgroundColor: '#fff!important',
      },
    },
  },
  switchGridLeft: {
    borderRadius: '10px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: '#EBEBEB',
    textAlign: 'center',
    padding: '8px',
    margin: '10px 0 20px 0',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
  },

  switchGridRight: {
    borderRadius: '10px',
    backgroundColor: '#EBEBEB',
    textAlign: 'center',
    padding: '8px',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
  },
  selectedRadio: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #99A2AC',
    color: '#171717',
    borderRadius: '7px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
  },
  selectedRadio1: {
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    border: '1px solid #99A2AC',
    padding: '8px',
    margin: '10px 0 20px 0',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  selectedRadio2: {
    backgroundColor: 'grey',
    textAlign: 'center',
    padding: '8px',
    margin: '10px 0 20px 0',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
}));
