import React from 'react';
import useStyles from './styles';
import { Grid } from '@material-ui/core';
import ClassNames from 'classnames';

export default function HBTapRadioButtons(props) {
  const classes = useStyles();
  const { data, onClick, defaultValue } = props;

  const [selected, setSelected] = React.useState(defaultValue || '');

  const onClickHandler = name => {
    setSelected(name);

    if (onClick) onClick(name);
  };

  return (
    <>
      <div
        style={{
          marginTop: '-10px',
          marginBottom: '-20px',
        }}>
        <Grid
          container
          justifyContent="flex-start"
          alignitems="center"
          style={{
            backgroundColor: '#EBEBEB',
            height: '65px',
            marginTop: '18px',
            marginBottom: '28px',
            borderRadius: '10px',
          }}>
          {data.map(itemData => {
            const { name, image } = itemData;
            return (
              <Grid xs={6} item>
                <div
                  onClick={() => onClickHandler(name)}
                  key={name}
                  className={ClassNames([
                    classes.switchGridRight,
                    selected === name ? classes.selectedRadio : '',
                  ])}>
                  <img src={image} alt="image"></img>
                  <span
                    style={{
                      color: '#171717',
                      fontSize: '16px',
                      fontFamily: 'Open Sans',
                      marginLeft: '10px',
                      fontWeight: '700',
                    }}>
                    {name}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
}
