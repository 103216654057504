import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CommonHeaderText from '@/components/packages/common-header-text';
import HorizontalScroll from '@/components/packages/horizontal-scroll';
import styled from 'styled-components';

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Button = styled.button`
  color: #6a6a6a;
  width: 75px;
  border: 1px solid #6a6a6a66;
  cursor: pointer;
  height: 65px;
  margin: 0 12px 5px 0;
  display: inline-block;
  // padding: 5px 25px;
  max-width: 80px;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  &:disabled {
    color: #333;
  }
`;

const ButtonToggle = styled(Button)`
  border: 1px solid #99a2ac;
  color: #6a6a6a;
  height: 85px;
  ${({ active }) =>
    active &&
    `
    border: 2px solid #0056B2;
    color: #171717,
  `}
`;

const useStyles = makeStyles(() => ({
  nextIcon: {
    position: 'relative',
    left: '96%',
    bottom: '62%',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '50%',
    height: '23px',
    width: '23px',
    cursor: 'pointer',
    '& img': {
      marginTop: '3px!important',
    },
  },
  prevIcon: {
    position: 'relative',
    left: '-3%',
    bottom: '45%',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '50%',
    height: '23px',
    width: '23px',
    cursor: 'pointer',
    '& img': {
      marginTop: '3px!important',
    },
  },
}));

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

function getDates(startDate, stopDate, monthsGet, weekdaysGet) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    let date = {
      weekday: weekdaysGet[currentDate.getDay()],
      day: currentDate.getDate(),
      month: monthsGet[currentDate.getMonth()],
      date: currentDate.toLocaleDateString('en-US'),
    };
    if (weekdaysGet[currentDate.getDay()] !== 'Tue') {
      dateArray.push(date);
    }
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

const HorizontalDateRange = props => {
  const { start_date, end_date, setDate } = props;
  const classes = useStyles();
  const [dates, setDates] = useState([]);
  const startDateWithoutTime = new Date(start_date);
  const endDateWithoutTime = new Date(end_date);
  startDateWithoutTime.setHours(0, 0, 0, 0);
  endDateWithoutTime.setHours(0, 0, 0, 0);

  const componentRef = useRef();
  const loadNextDates = () => {
    componentRef.current.handleArrowClickRight();
  };
  const loadPrevDates = () => {
    componentRef.current.handleArrowClick();
  };

  const startTime = startDateWithoutTime.getTime();
  const endTime = endDateWithoutTime.getTime();
  useEffect(() => {
    var datesarr = getDates(
      startDateWithoutTime,
      endDateWithoutTime,
      months,
      weekdays
    );
    setDates(datesarr);
    return;
  }, [startTime, endTime]);

  const setActive = (e, date) => {
    setDate(date);
    const revised_dates = [...dates];
    for (let i = 0; i < revised_dates.length; i++) {
      revised_dates[i].active = revised_dates[i].date == date;
    }
    setDates(revised_dates);
  };

  return (
    <>
      <div className={classes.root}>
        <HorizontalScroll
          componentRef={componentRef}
          dragging={true}
          data={
            dates &&
            dates.map((data, index) => {
              const { day, weekday, month, active, date } = data;
              return (
                <ButtonToggle
                  key={index}
                  value={date}
                  active={active}
                  onClick={e => {
                    setActive(e, date);
                  }}>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      color: '#171717',
                      fontFamily: 'Open Sans',
                    }}>
                    {weekday}
                  </span>
                  <CommonHeaderText
                    text={day}
                    variant="h5"
                    style={{
                      fontSize: '18px',
                      fontWeight: '700',
                      fontFamily: 'Open Sans',
                      color: '#171717',
                    }}
                  />
                  <CommonHeaderText
                    text={month}
                    variant="h5"
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      fontFamily: 'Open Sans',
                      color: '#171717',
                    }}
                  />
                </ButtonToggle>
              );
            })
          }></HorizontalScroll>
      </div>
      <div onClick={loadPrevDates} className={classes.prevIcon}>
        <img alt="Previous Icon" src="/static/images/prev-icon.svg"></img>
      </div>
      <div onClick={loadNextDates} className={classes.nextIcon}>
        <img alt="Next Icon" src="/static/images/next-icon.svg"></img>
      </div>
    </>
  );
};

export default HorizontalDateRange;
