import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export default styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0 , 0, 0, 0.3) !important',
  // backgroundColor: '#eeeeee !important',
  border: `1px solid ${theme?.palette?.border?.main} !important`,
  padding: '0.2rem !important',
  opacity: 0.8,
  color: '#fff !important',
  pointerEvents: 'initial',
  '&:hover': {
    backgroundColor: 'rgba(0 , 0, 0, 0.7) !important',
    // backgroundColor: 'lightgrey !important',
  },
}));
